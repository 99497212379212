import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as ST from './styled'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ButtonTypes } from 'constants/buttonTypes'
import BaseSelect, { IItem } from 'components/ui/BaseSelect'
import { RequiredFields } from 'constants/requiredFields'
import { IGeneralInfoEditingFormik } from 'types/userProfileTypes'
import {
  addSocialNetwork,
  changeUserInfo,
  postUserPersonalEditing,
  updateUserSocialNetwork,
} from 'api/userPage'
import { getCurrentDepartment, getDepartments } from 'api/department'
import { parseDataToDepartments } from 'utils/parseData'
import { ErrorMessages } from 'constants/errorMessages'
import { handlerError, setToastError } from 'utils/handlerError'
import { SocialNetworkBlock } from './socialNetworkBlock'
import { SocialNetworks } from 'constants/socialNetworks'
import { DepartmentTypes } from 'types/model/department'
import { ErrorMessage } from 'components/ui/ErrorMessage'
import { useGetGradesByPositionIdQuery } from 'store/api/grade'
import moment from 'moment'
import CalendarInput from 'components/ui/inputs/Calendar'
import { formatPhone, formatPhoneBack } from 'utils/phone'
import Loader from 'components/ui/Loader'
import { UserTypes } from 'types/model/user'
import BackButton from 'components/ui/buttons/BackButton'
import { DATE_FORMAT_DTO } from 'constants/Date'
import Languages from 'components/newCandidate/Languages'
import { NewCandidateData } from 'components/newCandidate/data'
import AutocompleteMultiple from 'components/ui/AutocompleteMultiple'
import { AvailablePhoneCodes } from 'components/ui/inputs/PhoneInput/data'
import PhoneInput from 'components/ui/inputs/PhoneInput'
import { phoneRuNullable } from 'utils/validation/tests'
import BaseInput from 'components/ui/inputs/BaseInput'
import { PhoneFormat } from 'constants/phone'
import { Placeholders } from 'constants/placeholders'
import { FormikFields } from '../../../../../constants/formikFields'

interface IEditingGeneralTabContent {
  handleIsEditing: () => void
  userId: number
  data: UserTypes.GeneralTabData & UserTypes.FullName
  userData: UserTypes.UserInfo
  updateUserData?: () => void
}

interface ISocialNetwork {
  index: number
  data: {
    userId: number
    name: SocialNetworks
    link: string
  }
}

export const enum EditingGeneralTabContentStrings {
  additionalPhoneNumber = 'additionalPhoneNumber',
  email = 'email',
  dateBorn = 'dateBorn',
  personalEmail = 'personalEmail',
  radio = 'radio',
  sex = 'sex',
  none = 'none',
  dateFormat = '##.##.####',
  phoneNumber = 'phoneNumber',
  contactPhonePlaceholder = '+7 (000) 000–00–00',
  contactPhoneFormat = '+7 (###) ###-##-##',
  surname = 'surname',
  name = 'name',
  emailPlaceholder = 'example@mail.com',
  corpEmailPlaceholder = 'example@profsoft.pro',
  male = 'Мужской',
  female = 'Женский',

  patronymic = 'patronymic',
  date = 'date',
  department = 'department',
  departmentPlaceholder = 'Выберите отдел',
  post = 'post',
  postPlaceholder = 'Выберите должность',
  grade = 'grade',
  gradePlaceholder = 'Выберите грейд',
  file = 'file',
  namePlaceholder = 'Имя',
  surnamePlaceholder = 'Фамилия',
  patronymicPlaceholder = 'Отчество',
  stack = 'stack',
}

const EMPTY_DEPARTMENT_ID = 0

const EditingGeneralTabContent = ({
  data,
  handleIsEditing,
  userId,
  userData,
  updateUserData,
}: IEditingGeneralTabContent) => {
  const [indexCount, setIndexCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [activeSelect, setActiveSelect] = useState<string | null>(null)
  const [selectedLanguage, setSelectedLanguage] =
    useState<IItem<number> | null>(
      userData.nativeLanguage
        ? { value: 0, item: userData.nativeLanguage }
        : null
    )

  const [listDepartment, setListDepartment] = useState<Array<IItem>>([])
  const [bossName, setBossName] = useState<string>('')
  const [valueSex, setValueSex] = useState<boolean>(data.sex ? data.sex : false)
  const [department, setDepartment] = useState<number>(
    data.department?.id || EMPTY_DEPARTMENT_ID
  )
  const [date, setDate] = useState<Date>(
    data.dateBorn ? new Date(data.dateBorn) : new Date(Date.now())
  )
  const [allPositions, setAllPositions] = useState<DepartmentTypes.Position[]>(
    []
  )
  const [newSocialNetworks, setNewSocialNetworks] = useState<ISocialNetwork[]>(
    []
  )
  const [technologyStack, setTechnologyStack] = useState<string[]>(
    data.technologyStack
  )

  useEffect(() => {
    department > 0 &&
      getCurrentDepartment(department)
        .then((dep) => {
          dep.director &&
            setBossName(dep.director.name + ' ' + dep.director.surname)
          setAllPositions(dep.positions)
        })
        .catch(setToastError)
        .finally(() => {
          setIsLoading(false)
        })
  }, [department])

  const positionItems: IItem[] = useMemo(
    () =>
      allPositions.map((pos) => ({
        value: pos.id,
        item: pos.name,
      })),
    [allPositions]
  )

  const handleLanguageChange = (newValue: IItem<number> | null) => {
    setSelectedLanguage(newValue)
  }

  const updateForeignLanguageList = () => {
    setFieldValue('foreignLanguage', [
      ...values.foreignLanguage,
      { ...NewCandidateData.ForeignLanguagePattern },
    ])
  }

  const {
    handleSubmit,
    handleChange,
    values,
    setFieldValue,
    errors,
    isValid,
    isSubmitting,
  } = useFormik<IGeneralInfoEditingFormik>({
    initialValues: {
      phoneNumber: formatPhone(
        data.phoneNumber ?? data.phones?.[0]?.number ?? null
      ),
      additionalPhoneNumber: formatPhone(
        userData.additionalPhoneNumber ?? null
      ),
      dateBorn: moment(new Date(data.dateBorn)).format(DATE_FORMAT_DTO),
      sex: data.sex ? data.sex : false,
      email: data.email || '',
      personalEmail: data.emailPersonal ? data.emailPersonal : '',
      department: data.department?.name || '',
      surname: data.surname || '',
      name: data.name || '',
      patronymic: data.patronymic || '',
      grade: userData.grade?.id ?? 0,
      technologyStack: data.technologyStack,
      position: userData.position?.id ?? 0,
      nativeLanguage: userData.nativeLanguage ?? '',
      foreignLanguage: userData.foreignLanguage ?? [],
    },
    onSubmit: async () => {
      const newObj = {
        email: values.email,
        personalEmail: values.personalEmail,
        phoneNumber: formatPhoneBack(values.phoneNumber),
        dateBorn: moment(date).format(DATE_FORMAT_DTO),
        sex: valueSex,
        grade: values.grade,
        department,
        technologyStacks: technologyStack,
        position: values.position,
        additionalPhoneNumber: formatPhoneBack(values.additionalPhoneNumber),
        nativeLanguage:
          typeof values.nativeLanguage === 'string'
            ? values.nativeLanguage
            : values.nativeLanguage?.name ?? null,
        foreignLanguage: values.foreignLanguage,
      }

      const newPersonalObj: UserTypes.Personal = {
        surname: values.surname,
        name: values.name,
        patronymic: values.patronymic,
        phoneNumber: formatPhoneBack(values.phoneNumber)!,
        additionalPhoneNumber: formatPhoneBack(values.additionalPhoneNumber)!,
        sex: valueSex,
        dateBorn: moment(date).format(DATE_FORMAT_DTO),
      }
      const socialNetworkNew = [
        ...newSocialNetworks
          .filter((sn) => sn.data.name !== SocialNetworks.NOT_SET)
          .map((sn) => sn.data),
      ]
      try {
        await changeUserInfo(userId, newObj)
        await postUserPersonalEditing(userId, newPersonalObj)

        const editedSNPromises: Promise<number>[] = []
        const newSNPromises: Promise<number>[] = []

        for (let i = 0; i < editedSocialNetworks.length; i++) {
          const { id, link, name } = editedSocialNetworks[i]
          editedSNPromises.push(updateUserSocialNetwork(id, name, link))
        }
        for (let i = 0; i < socialNetworkNew.length; i++) {
          socialNetworkNew[i].link &&
            socialNetworkNew[i].name !== 0 &&
            newSNPromises.push(
              addSocialNetwork(
                userId,
                socialNetworkNew[i].name,
                socialNetworkNew[i].link
              )
            )
        }

        Promise.all<Promise<number>[]>([...editedSNPromises, ...newSNPromises])
          .then(() => {
            handleIsEditing()
            updateUserData?.()
          })
          .catch(setToastError)
      } catch (err) {
        handlerError(err)
      }
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required(RequiredFields.base)
        .email(ErrorMessages.incorrectEmail),
      personalEmail: Yup.string()
        .required(RequiredFields.base)
        .email(ErrorMessages.incorrectEmail),
      phoneNumber: Yup.string()
        .required(RequiredFields.base)
        .test(
          'phone-test',
          ErrorMessages.incorrectPhoneFormat,
          phoneRuNullable
        ),
      additionalPhoneNumber: Yup.string()
        .nullable()
        .test(
          'phone-test',
          ErrorMessages.incorrectPhoneFormat,
          phoneRuNullable
        ),
      dateBorn: Yup.string().required(RequiredFields.base),
      name: Yup.string().required(RequiredFields.base),
      surname: Yup.string().required(RequiredFields.base),
      department: Yup.string().required(RequiredFields.base),
      grade: Yup.number()
        .nullable()
        .test('equals', RequiredFields.base, (value) => !!value && value !== 0),
      position: Yup.number()
        .nullable()
        .test('equals', RequiredFields.base, (value) => !!value && value !== 0),
    }),
  })

  const { data: positionGrades, isFetching } = useGetGradesByPositionIdQuery(
    values.position,
    { skip: !values.position }
  )

  const gradesItems: IItem[] = useMemo(
    () =>
      positionGrades?.map((grade) => ({
        value: grade.id,
        item: grade.name,
      })) || [],
    [positionGrades]
  )

  const [oldSocialNetworks, setOldSocialNetworks] = useState<
    {
      id: number
      name: SocialNetworks
      link: string
    }[]
  >(data.socialNetwork)

  const [editedSocialNetworks, setEditedSocialNetworks] = useState<
    {
      id: number
      name: SocialNetworks
      link: string
    }[]
  >([])

  const deleteFromArrState = (index: number, isNew: boolean) => {
    if (isNew) {
      const newArr = newSocialNetworks.filter(
        (item, indexInArray) => indexInArray !== index
      )
      setNewSocialNetworks(newArr)
    } else {
      const newArr = oldSocialNetworks.filter(
        (item, indexInArr) => indexInArr !== index
      )
      setOldSocialNetworks(newArr)
    }
  }

  const passDepartmentValue = (
    type: string,
    currentValue: boolean | number | string
  ): void => {
    values.department =
      listDepartment.filter((item) => item.value === currentValue)?.[0]?.item ??
      ''
    setDepartment(currentValue ? +currentValue : EMPTY_DEPARTMENT_ID)
    setFieldValue('position', 0)
    setFieldValue('grade', 0)
  }

  const updateObjArr = useCallback(
    (
      obj: { userId: number; name: SocialNetworks; link: string },
      index: number
    ) => {
      setNewSocialNetworks((p) =>
        p.map((item, indexInArr) =>
          index === indexInArr
            ? {
                index,
                data: { ...obj },
              }
            : item
        )
      )
    },
    []
  )

  useEffect(() => {
    getDepartments()
      .then((resp) => {
        setListDepartment(resp?.length ? parseDataToDepartments(resp) : [])
      })
      .catch(setToastError)
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return (
    <>
      <ST.ManageBlock>
        <ST.BackBlock>
          <BackButton onClick={handleIsEditing} />

          <ST.EditingHeader>редактирование</ST.EditingHeader>
          {!isValid && <ErrorMessage message={RequiredFields.base2} />}
        </ST.BackBlock>
        <ST.SaveButton
          disabled={isSubmitting || isLoading || isFetching || !isValid}
          type={ButtonTypes.submit}
          onClick={() => handleSubmit()}
        >
          Сохранить
        </ST.SaveButton>
      </ST.ManageBlock>
      {isLoading || isLoading ? (
        <Loader />
      ) : (
        <>
          <ST.Headers>
            <ST.HeaderBlock>ФИО</ST.HeaderBlock>
          </ST.Headers>
          <ST.FullName>
            <ST.InputWrapper>
              <ST.Label>
                Фамилия <ST.Star>*</ST.Star>
              </ST.Label>
              <ST.Input
                placeholder={EditingGeneralTabContentStrings.surnamePlaceholder}
                id={EditingGeneralTabContentStrings.surname}
                name={EditingGeneralTabContentStrings.surname}
                value={values.surname}
                onChange={handleChange}
              />
            </ST.InputWrapper>
            <ST.InputWrapper>
              <ST.Label>
                Имя <ST.Star>*</ST.Star>
              </ST.Label>
              <ST.Input
                placeholder={EditingGeneralTabContentStrings.namePlaceholder}
                id={EditingGeneralTabContentStrings.name}
                name={EditingGeneralTabContentStrings.name}
                value={values.name}
                onChange={handleChange}
              />
            </ST.InputWrapper>
            <ST.InputWrapper>
              <ST.Label>Отчество</ST.Label>
              <ST.Input
                placeholder={
                  EditingGeneralTabContentStrings.patronymicPlaceholder
                }
                id={EditingGeneralTabContentStrings.patronymic}
                name={EditingGeneralTabContentStrings.patronymic}
                value={values.patronymic}
                onChange={handleChange}
              />
            </ST.InputWrapper>
          </ST.FullName>
          <ST.Headers>
            <ST.HeaderBlock>Личные данные</ST.HeaderBlock>
          </ST.Headers>
          <ST.Personal>
            <ST.InputWrapper>
              <ST.Label>
                Дата рождения<ST.Star>*</ST.Star>
              </ST.Label>
              <CalendarInput setStartDate={setDate} startDate={date} />
            </ST.InputWrapper>
            <ST.InputWrapper>
              <ST.Label>
                Пол<ST.Star>*</ST.Star>
              </ST.Label>
              <ST.RadioWrapper>
                <ST.RadioItem>
                  <ST.Radiobutton
                    type={EditingGeneralTabContentStrings.radio}
                    value={EditingGeneralTabContentStrings.male}
                    checked={valueSex}
                    name={EditingGeneralTabContentStrings.sex}
                    onChange={() => setValueSex(true)}
                  />
                  <ST.RadioLabel onClick={() => setValueSex(true)}>
                    Мужской
                  </ST.RadioLabel>
                </ST.RadioItem>
                <ST.RadioItem>
                  <ST.Radiobutton
                    type={EditingGeneralTabContentStrings.radio}
                    value={EditingGeneralTabContentStrings.female}
                    checked={!valueSex}
                    name={EditingGeneralTabContentStrings.sex}
                    onChange={() => setValueSex(false)}
                  />
                  <ST.RadioLabel onClick={() => setValueSex(false)}>
                    Женский
                  </ST.RadioLabel>
                </ST.RadioItem>
              </ST.RadioWrapper>
            </ST.InputWrapper>
            <ST.InputWrapper>
              <BaseInput
                id={EditingGeneralTabContentStrings.email}
                name={EditingGeneralTabContentStrings.email}
                label="Корпоративный email"
                placeholder={
                  EditingGeneralTabContentStrings.corpEmailPlaceholder
                }
                value={values.email}
                error={errors.email ?? ''}
                onChange={handleChange}
                required={true}
              />
            </ST.InputWrapper>
            <ST.InputWrapper>
              <BaseInput
                id={EditingGeneralTabContentStrings.personalEmail}
                name={EditingGeneralTabContentStrings.personalEmail}
                label="Личный email"
                placeholder={EditingGeneralTabContentStrings.emailPlaceholder}
                value={values.personalEmail}
                error={errors.personalEmail ?? ''}
                onChange={handleChange}
                required={true}
              />
            </ST.InputWrapper>

            <ST.InputWrapper>
              <PhoneInput
                customInput={BaseInput}
                format={PhoneFormat.numberFormat}
                placeholder={Placeholders.phone}
                id={EditingGeneralTabContentStrings.phoneNumber}
                name={EditingGeneralTabContentStrings.phoneNumber}
                value={values.phoneNumber}
                error={errors.phoneNumber}
                onChange={handleChange}
                required={true}
                label="Телефон"
              />
            </ST.InputWrapper>
            <ST.InputWrapper>
              <PhoneInput
                customInput={BaseInput}
                format={PhoneFormat.numberFormat}
                placeholder={Placeholders.phone}
                id={EditingGeneralTabContentStrings.additionalPhoneNumber}
                name={EditingGeneralTabContentStrings.additionalPhoneNumber}
                value={values.additionalPhoneNumber}
                error={errors.additionalPhoneNumber}
                onChange={handleChange}
                label="Дополнительный телефон"
              />
            </ST.InputWrapper>
          </ST.Personal>
          <ST.DepartmentBlock>
            <ST.SelectWrapper>
              <ST.HeaderBlock>Отдел</ST.HeaderBlock>
              <ST.InputWrapper>
                <ST.Label>
                  Отдел<ST.Star>*</ST.Star>
                </ST.Label>
                <BaseSelect
                  defaultSelect
                  placeHolder={
                    EditingGeneralTabContentStrings.departmentPlaceholder
                  }
                  listItems={listDepartment}
                  name={EditingGeneralTabContentStrings.department}
                  value={values.department ?? ''}
                  typeSelect={EditingGeneralTabContentStrings.department}
                  passValue={passDepartmentValue}
                  setActive={setActiveSelect}
                  activeSelect={activeSelect}
                  removable={true}
                />
              </ST.InputWrapper>
              <ST.InputWrapper>
                <ST.Label>
                  Должность<ST.Star>*</ST.Star>
                </ST.Label>
                <BaseSelect
                  defaultSelect
                  placeHolder={EditingGeneralTabContentStrings.postPlaceholder}
                  listItems={positionItems}
                  name={EditingGeneralTabContentStrings.post}
                  value={
                    positionItems.find((pos) => pos.value === values.position)
                      ?.item ?? ''
                  }
                  typeSelect={EditingGeneralTabContentStrings.post}
                  passValue={(_, currentValue) => {
                    setFieldValue(
                      'position',
                      currentValue ? +currentValue : null
                    )
                    setFieldValue('grade', null)
                  }}
                  setActive={setActiveSelect}
                  activeSelect={activeSelect}
                  removable
                />
              </ST.InputWrapper>
            </ST.SelectWrapper>
            <ST.SelectWrapper>
              <ST.HeaderBlock>Руководитель</ST.HeaderBlock>
              <ST.InputWrapper>
                <ST.Label>
                  Фамилия и имя<ST.Star>*</ST.Star>
                </ST.Label>
                <ST.Input
                  id={EditingGeneralTabContentStrings.email}
                  name={EditingGeneralTabContentStrings.email}
                  value={bossName}
                  onChange={handleChange}
                  disabled
                />
              </ST.InputWrapper>
              <ST.InputWrapper>
                <ST.Label>
                  Грейд<ST.Star>*</ST.Star>
                </ST.Label>
                <BaseSelect
                  defaultSelect
                  placeHolder={EditingGeneralTabContentStrings.gradePlaceholder}
                  listItems={gradesItems}
                  name={EditingGeneralTabContentStrings.grade}
                  value={
                    gradesItems.find((grade) => grade.value === values.grade)
                      ?.item ?? ''
                  }
                  typeSelect={EditingGeneralTabContentStrings.grade}
                  passValue={(_, currentValue) => {
                    setFieldValue('grade', currentValue ? +currentValue : null)
                  }}
                  setActive={setActiveSelect}
                  activeSelect={activeSelect}
                  disabled={isFetching}
                  removable
                />
              </ST.InputWrapper>
            </ST.SelectWrapper>
          </ST.DepartmentBlock>
          <ST.SocialNetworkWrapper>
            <ST.HeaderBlock>Социальные сети</ST.HeaderBlock>
            {oldSocialNetworks.map((item, index) => (
              <SocialNetworkBlock
                isNew={false}
                data={item}
                key={'sn' + item.id}
                userId={userId}
                index={index}
                isSaved={isSubmitting}
                deleteFromArr={deleteFromArrState}
                edited={editedSocialNetworks}
                setEdited={setEditedSocialNetworks}
              />
            ))}
            {newSocialNetworks?.map((item, index) => (
              <SocialNetworkBlock
                isNew
                key={item.index}
                deleteFromArr={deleteFromArrState}
                index={index}
                userId={userId}
                isSaved={isSubmitting}
                dataNew={item.data}
                updateObjArr={updateObjArr}
              />
            ))}
            <ST.Button
              onClick={() => {
                setIndexCount((prev) => prev + 1)
                setNewSocialNetworks((prevState) => [
                  ...prevState,
                  {
                    index: indexCount,
                    data: {
                      userId: userId,
                      name: SocialNetworks.NOT_SET,
                      link: '',
                    },
                  },
                ])
              }}
            >
              <ST.Plus /> Добавить социальную сеть
            </ST.Button>
          </ST.SocialNetworkWrapper>
          <ST.StackWrapper>
            <ST.HeaderBlock>Профессиональные навыки</ST.HeaderBlock>
            <AutocompleteMultiple
              options={technologyStack}
              value={technologyStack}
              onChange={(value) => {
                setTechnologyStack(value)
              }}
              preserveOptions
              freeSolo
            />
          </ST.StackWrapper>
          <Languages
            setFieldValue={setFieldValue}
            values={values}
            selectedLanguage={selectedLanguage}
            handleLanguageChange={handleLanguageChange}
            updateForeignLanguageList={updateForeignLanguageList}
            isForUser
          />
        </>
      )}
    </>
  )
}

export default EditingGeneralTabContent
