import React, { useEffect, useRef, useState } from 'react'
import * as ST from './styled'
import { getCurrentDepartment } from 'api/department'
import { handlerError } from 'utils/handlerError'
import { useNavigate, useParams } from 'react-router-dom'
import DirectorLine from './DirectorLine'
import PositionsLine from './PositionsLine'
import { DepartmentTypes } from 'types/model/department'
import BackButton from 'components/ui/buttons/BackButton'
import { PATHS } from 'constants/paths'
import { useTitle } from 'hooks/useTitle'
import Loader from 'components/ui/Loader'
import Body from 'components/ui/layout/Body'

const Department = () => {
  const navigate = useNavigate()
  const { departmentId } = useParams()
  const [department, setDepartment] = useState<DepartmentTypes.Model | null>(
    null
  )
  const originalTitle = useRef<string>(document.title)

  useEffect(() => {
    departmentId &&
      getCurrentDepartment(+departmentId)
        .then((data) => setDepartment(data))
        .catch((err) => handlerError(err))
  }, [departmentId])

  const goToDepartments = () => {
    navigate(PATHS.departments)
  }
  useTitle(department?.name ?? originalTitle.current)

  return (
    <>
      {department ? (
        <Body>
          <ST.DepartmentHead>
            <BackButton onClick={goToDepartments} />
            <ST.DepartmentsTitle>{department.name}</ST.DepartmentsTitle>
          </ST.DepartmentHead>
          <ST.DepartmentBody>
            <ST.DepartmentCard>
              {department.director && (
                <DirectorLine
                  director={department.director}
                  departmentId={departmentId}
                />
              )}
              <PositionsLine positions={department.positions} />
            </ST.DepartmentCard>
          </ST.DepartmentBody>
        </Body>
      ) : (
        <Loader />
      )}
    </>
  )
}

export default Department
