import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as ST from './styled'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { getEmployees } from 'api/employees'
import {
  addDepartmentPosition,
  editDepartment,
  getCurrentDepartment,
  removeDepartmentPosition,
} from 'api/department'
import { handlerError } from 'utils/handlerError'
import { DepartmentTypes } from 'types/model/department'
import BackButton from 'components/ui/buttons/BackButton'
import BaseButton from 'components/ui/buttons/BaseButton'
import { IItem } from 'components/ui/BaseSelect'
import PositionInput from './PositionInput'
import AddPositionButton from './AddPositionButton'
import { getPositions } from 'api/position'
import {
  parseDataToDynamicPositions,
  parseDataToEmployees,
} from 'utils/parseData'
import { PATHS } from 'constants/paths'
import { RequiredFields } from 'constants/requiredFields'
import { ButtonLabels } from 'constants/buttonLabels'
import { InputSizes } from 'constants/inputSizes'
import { Placeholders } from 'constants/placeholders'
import { ButtonTypes } from 'constants/buttonTypes'
import { useTitle } from 'hooks/useTitle'
import { PAGES } from 'constants/pages'
import debounce from 'lodash/debounce'
import { Autocomplete, Box, TextField, useMediaQuery } from '@mui/material'
import { BreakPoints } from 'constants/breakPoints'
import Body from 'components/ui/layout/Body'
import Loader from 'components/ui/Loader'

export interface IDynamicPosition extends IItem {
  new?: boolean
}

const inputName = 'name'
const directorInputName = 'director'

const EditDepartment = () => {
  useTitle(PAGES.editDepartment)
  const navigate = useNavigate()
  const { departmentId } = useParams()
  const isMobileDevice = useMediaQuery(
    `(max-width: ${BreakPoints.TABLETS_PORTRAIT})`
  )

  const [department, setDepartment] = useState<DepartmentTypes.Model | null>(
    null
  )
  const [allPositions, setAllPositions] = useState<IDynamicPosition[]>([])
  const [positions, setPositions] = useState<IDynamicPosition[]>([])
  const [newPositionCount, setNewPositionCount] = useState<number>(0)
  const [filterUserList, setFilterUserList] = useState<Array<IItem>>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false)

  const updateDepartment = (id: number) => {
    getCurrentDepartment(id)
      .then((data) => {
        setDepartment(data)
        setPositions(parseDataToDynamicPositions(data.positions))
        setBtnDisabled(true)
      })
      .catch((err) => handlerError(err))
      .finally(() => {
        setIsLoading(false)
        setBtnDisabled(false)
      })
  }

  const fetchLists = async () => {
    await getPositions()
      .then((data) => setAllPositions(parseDataToDynamicPositions(data)))
      .catch((err) => handlerError(err))
  }

  const handleInputChange = (value: string) => {
    getEmployees(1, {}, value)
      .then((data) => {
        setFilterUserList(parseDataToEmployees(data.data))
      })
      .catch((e) => {
        handlerError(e)
      })
  }

  const { handleChange, values, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: department?.name || '',
      director: {
        value: department?.director?.id ?? 0,
        item: department?.director
          ? `${department?.director.name} ${department?.director.surname}`
          : '',
      },
    },
    onSubmit: async (data) => {
      const newPositions = positions
        .map((item) => String(item.value))
        .filter((item) => item && !item.includes('new'))

      await editDepartment(+departmentId!, {
        name: data.name,
        director: data.director.value,
        positions: newPositions,
      })
      departmentId && updateDepartment(+departmentId)
      await fetchLists()
      navigate(PATHS.departments)
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(RequiredFields.base),
      director: Yup.object().required(RequiredFields.base),
    }),
  })

  useEffect(() => {
    void fetchLists()
  }, [])

  useEffect(() => {
    departmentId && updateDepartment(+departmentId)
  }, [departmentId])

  const isValid = useMemo(
    () =>
      values.name &&
      values.director?.value &&
      values.director?.value !== -1 &&
      positions.length,
    [values.director?.value, values.name, positions]
  )

  useEffect(() => {
    setBtnDisabled(!isValid)
  }, [isValid])

  const removePosition = (indexToRemove: number) => {
    if (department) {
      setPositions(positions.filter((_, index) => index !== indexToRemove))
    }
  }

  const createPosition = () => {
    setPositions([
      ...positions,
      {
        value: `new-${newPositionCount}`,
        item: '',
        new: true,
      },
    ])
    setNewPositionCount((prevState) => prevState + 1)
  }

  return (
    <Body>
      <ST.EditDepartmentHead>
        <ST.HeadDepartment>
          <BackButton onClick={() => navigate(PATHS.departments)} />
          <ST.TitleDepartment>редактирование</ST.TitleDepartment>
        </ST.HeadDepartment>
        <ST.Button>
          <BaseButton
            type={'submit'}
            text={ButtonLabels.save}
            typeButton={ButtonTypes.primary}
            onClick={handleSubmit}
            fakeDisabled={btnDisabled}
          />
        </ST.Button>
      </ST.EditDepartmentHead>
      <ST.EditDepartmentBody>
        {isLoading ? (
          <Loader marginTop="40px" />
        ) : (
          <>
            <ST.DepartmentHead>
              <ST.InputWrapper>
                <ST.Label>
                  Название отдела<ST.Star>*</ST.Star>
                </ST.Label>
                <ST.Input
                  placeholder={Placeholders.developmentDepartment}
                  sizeInput={InputSizes.small}
                  id={inputName}
                  name={inputName}
                  value={values.name}
                  onChange={handleChange}
                />
              </ST.InputWrapper>
              <ST.SelectWrapper>
                <ST.Label>
                  Руководитель<ST.Star>*</ST.Star>
                </ST.Label>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  clearOnBlur={false}
                  blurOnSelect={true}
                  noOptionsText={'Нет доступных вариантов'}
                  options={filterUserList}
                  style={
                    isMobileDevice
                      ? {
                          display: 'flex',
                          alignItems: 'center',
                          color: '#000000',
                          backgroundColor: '#f8f8f8',
                          boxSizing: 'border-box',
                          borderRadius: '10px',
                          height: '48px',
                          fontWeight: '400',
                          fontSize: '16px',
                          width: '100%',
                        }
                      : {
                          color: '#000000',
                          backgroundColor: '#f8f8f8',
                          padding: '0',
                          marginBottom: '30px',
                          borderRadius: '10px',
                          height: '20px',
                          fontWeight: '400',
                          fontSize: '16px',
                          width: '100%',
                        }
                  }
                  sx={{
                    '& .MuiInputBase-root': {
                      padding: '0 !important',
                    },
                    '& .Mui-focused.MuiFormLabel-root.MuiInputLabel-root': {
                      opacity: '0',
                    },
                    '& .MuiFormLabel-filled': {
                      opacity: '0 !important',
                    },
                    '& .MuiFormLabel-filled + div > fieldset > legend': {
                      display: 'none !important',
                    },
                    '& .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                      {
                        borderColor: 'transparent',
                      },
                    '& .MuiFormLabel-root': {
                      top: '-8px',
                      color: '#606060',
                      opacity: '0.5',
                      fontFamily: 'Golos, sans-serif !important',
                    },
                    '& .MuiAutocomplete-input': {
                      ...(isMobileDevice
                        ? {
                            paddingLeft: '16px !important',
                            paddingRight: '16px !important',
                            fontFamily: 'Golos, sans-serif !important',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '140%',
                            color: 'black',
                          }
                        : {
                            paddingLeft: '10px !important',
                            fontFamily: 'Golos, sans-serif !important',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '140%',
                            color: 'black',
                          }),
                    },
                    '& fieldset': {
                      border: 'none !important',
                    },
                  }}
                  filterSelectedOptions
                  value={values.director}
                  onInputChange={debounce(
                    (e: any) => handleInputChange(e.target.value),
                    1000
                  )}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.value === value.value
                  }
                  filterOptions={(options: any) => options}
                  getOptionLabel={(option: any) => option.item}
                  onChange={(event, value: any) => {
                    if (value) {
                      setFieldValue(directorInputName, value)
                    } else {
                      setFieldValue(directorInputName, { item: '', value: -1 })
                    }
                  }}
                  renderOption={(props: any, option: any) => {
                    return (
                      <Box
                        component="li"
                        style={{
                          backgroundColor: '#fefefe',
                          border: 'none',
                          color: '#898989',
                        }}
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                        key={option.value}
                      >
                        {option.item}
                      </Box>
                    )
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      style={{
                        backgroundColor: '#f8f8f8',
                        borderWidth: 0,
                        '::placeholder': {
                          opacity: '0.5',
                        },
                        borderRadius: '10px',
                      }}
                      {...params}
                      label="Руководитель"
                    />
                  )}
                />
              </ST.SelectWrapper>
            </ST.DepartmentHead>
            <ST.Label>
              Должность<ST.Star>*</ST.Star>
            </ST.Label>
            <ST.PositionsBlock>
              {positions.map((item, index) => (
                <ST.PositionWrapper key={String(item.value)}>
                  <PositionInput
                    value={item}
                    listItems={allPositions}
                    selectedItems={positions}
                    isSmallSelect
                    placeholder={Placeholders.choosePosition}
                    onChange={(newValue) => {
                      if (newValue) {
                        const newPositions = positions
                        newPositions[index] = newValue
                        setPositions(newPositions)
                      }
                    }}
                    onRemove={() => removePosition(index)}
                  />
                </ST.PositionWrapper>
              ))}
            </ST.PositionsBlock>
            <AddPositionButton onClick={createPosition} />
          </>
        )}
      </ST.EditDepartmentBody>
    </Body>
  )
}

export default EditDepartment
