import React, { FC, useMemo } from 'react'
import * as ST from './styled'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import BaseSelect, { IItem } from 'components/ui/BaseSelect'
import { IDynamicPosition } from 'components/departments/EditDepartment'
import { RequiredFields } from 'constants/requiredFields'
import IconButton from 'components/ui/buttons/IconButton'
import { ReactComponent as DeleteIcon } from 'assets/icons/trash.svg'

interface Props {
  value: IDynamicPosition
  placeholder?: string
  listItems: IItem[]
  selectedItems: IDynamicPosition[]
  isSmallSelect?: boolean
  disabledDelete?: boolean
  onChange: (newValue: IDynamicPosition) => void
  onRemove: () => void
}

const PositionInput: FC<Props> = ({
  value,
  placeholder,
  listItems,
  selectedItems,
  isSmallSelect,
  disabledDelete,
  onChange,
  onRemove,
}) => {
  const { values, errors } = useFormik({
    initialValues: {
      position: value.item,
    },
    onSubmit: () => {},
    validationSchema: Yup.object().shape({
      position: Yup.string().required(RequiredFields.base),
    }),
  })

  const listItemsData = useMemo(() => {
    const selectedIds = selectedItems.map((item) => item.value)
    return listItems.filter((item) => !selectedIds.includes(item.value))
  }, [listItems, selectedItems])

  return (
    <ST.Container isSmallSelect={isSmallSelect}>
      <ST.Content isSmallSelect={isSmallSelect}>
        <BaseSelect
          isSmallSelect={isSmallSelect}
          placeHolder={placeholder || 'Выберите значение'}
          listItems={listItemsData}
          name={'position'}
          value={values.position}
          typeSelect={'departmentHead'}
          onChange={(newItem) => {
            onChange && newItem && onChange({ ...newItem, new: value.new })
          }}
          disabled={!value.new}
          hideArrow={!value.new}
          removable
        />
        <IconButton
          icon={DeleteIcon}
          disabled={disabledDelete}
          onClick={onRemove}
        />
      </ST.Content>
      {errors.position ? <ST.Error>{errors.position}</ST.Error> : null}
    </ST.Container>
  )
}

export default PositionInput
