import * as Yup from 'yup'
import { RequiredFields } from 'constants/requiredFields'

export enum Fields {
  systemName = 'systemName',
  logo = 'logo',
  timeZone = 'timeZone',
  policyLink = 'policyLink',
}

export enum Placeholders {
  systemName = 'Краткое название компании',
  logo = 'Загрузить логотип',
  doc = 'Загрузить документ',
  docReplacement = 'Заменить документ',
  logoReplacement = 'Заменить логотип',
  logoPreview = 'Превью логотипа',
  timeZone = 'Выберите значение',
  policyLink = 'Укажите ссылку на документ',
}

export const InitialValues = {
  logo: '',
  logoPath: '',
  systemName: '',
  timeZone: '',
  policyLink: '',
}

export const ValidationSchema = Yup.object().shape({
  systemName: Yup.string().trim().required(RequiredFields.base),
  timeZone: Yup.string().trim().required(RequiredFields.base),
  policyLink: Yup.string().trim().required(RequiredFields.base),
})
