import React, { useEffect, useRef, useState } from 'react'
import * as ST from './styled'
import { handlerError } from 'utils/handlerError'
import { useNavigate, useParams } from 'react-router-dom'
import BackButton from 'components/ui/buttons/BackButton'
import { getCurrentPosition } from 'api/position'
import { PositionTypes } from 'types/model/position'
import PositionLine from './PositionLine'
import GradesLine from './GradesLine'
import { PATHS } from 'constants/paths'
import { useTitle } from 'hooks/useTitle'
import Loader from 'components/ui/Loader'
import Body from 'components/ui/layout/Body'

const Position = () => {
  const navigate = useNavigate()
  const { positionId } = useParams()
  const [position, setPosition] = useState<PositionTypes.Model | null>(null)
  const originalTitle = useRef<string>(document.title)

  useEffect(() => {
    positionId &&
      getCurrentPosition(+positionId)
        .then((data) => setPosition(data))
        .catch((err) => handlerError(err))
  }, [positionId])

  const goToPositions = () => {
    navigate(PATHS.positions)
  }

  useTitle(position?.name ?? originalTitle.current)

  return (
    <>
      {position ? (
        <Body>
          <ST.PositionHead>
            <BackButton onClick={goToPositions} />
            <ST.PositionTitle>{position.name}</ST.PositionTitle>
          </ST.PositionHead>
          <ST.PositionBody>
            <ST.PositionCard>
              <PositionLine
                positionName={position.name}
                positionId={String(positionId)}
              />
              <GradesLine grades={position.grades} />
            </ST.PositionCard>
          </ST.PositionBody>
        </Body>
      ) : (
        <Loader />
      )}
    </>
  )
}

export default Position
