export const TimeZones = [
  { item: 'UTC -11:00', value: 1 },
  { item: 'UTC -10:00', value: 2 },
  { item: 'UTC -09:00', value: 3 },
  { item: 'UTC -08:00', value: 4 },
  { item: 'UTC -07:00', value: 5 },
  { item: 'UTC -06:00', value: 6 },
  { item: 'UTC -05:00', value: 7 },
  { item: 'UTC -04:00', value: 8 },
  { item: 'UTC -03:00', value: 9 },
  { item: 'UTC -02:00', value: 10 },
  { item: 'UTC -01:00', value: 11 },
  { item: 'UTC 00:00', value: 12 },
  { item: 'UTC +01:00', value: 13 },
  { item: 'UTC +02:00', value: 14 },
  { item: 'UTC +03:00', value: 15 },
  { item: 'UTC +04:00', value: 16 },
]
