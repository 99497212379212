import { IItem } from 'components/ui/BaseSelect'
import { HiringStepsEnum, SettingsSteps } from './settingsSteps'

export enum SettingsStepsDescription {
  GENERAL = 'Общие',
  CONTACTS = 'Контактные данные',
  DOCUMENT_PATTERNS = 'Шаблоны документов',
  HIRING_STAGES = 'Этапы найма',
  HARDWARE_TYPES = 'Типы оборудования',
  INTEGRATIONS = 'Интеграции',
  BENEFIT_TYPES = 'Виды бенефитов',
  LETTER_TEMPLATES = 'Шаблоны писем',
  HIRING_TYPES = 'Типы найма',
}

export const listSettingsSteps: IItem<
  SettingsSteps,
  SettingsStepsDescription
>[] = [
  {
    item: SettingsStepsDescription.GENERAL,
    value: SettingsSteps.GENERAL,
  },
  {
    item: SettingsStepsDescription.CONTACTS,
    value: SettingsSteps.CONTACTS,
  },
  {
    item: SettingsStepsDescription.DOCUMENT_PATTERNS,
    value: SettingsSteps.DOCUMENT_PATTERNS,
  },
  {
    item: SettingsStepsDescription.LETTER_TEMPLATES,
    value: SettingsSteps.LETTER_TEMPLATES,
  },
  {
    item: SettingsStepsDescription.HIRING_STAGES,
    value: SettingsSteps.HIRING_STAGES,
  },
  {
    item: SettingsStepsDescription.HIRING_TYPES,
    value: SettingsSteps.HIRING_TYPES,
  },
  {
    item: SettingsStepsDescription.HARDWARE_TYPES,
    value: SettingsSteps.HARDWARE_TYPES,
  },
  {
    item: SettingsStepsDescription.INTEGRATIONS,
    value: SettingsSteps.INTEGRATIONS,
  },
  {
    item: SettingsStepsDescription.BENEFIT_TYPES,
    value: SettingsSteps.BENEFIT_TYPES,
  },
]

export enum HiringStepsDescription {
  INITIAL_STATUS = 'Начальный статус',
  OFFER = 'Оффер',
  REJECTION = 'Отказ кандидату',
  CLOSE_VACANCY = 'Закрытие вакансии',
  ACCEPTED = 'Кандидат принят',
  RESUME_COMPLETION = 'Заполнение резюме',
  EMPTY = '',
}

export const HiringStepsLabels = {
  [HiringStepsEnum.INITIAL_STATUS]: HiringStepsDescription.INITIAL_STATUS,
  [HiringStepsEnum.OFFER]: HiringStepsDescription.OFFER,
  [HiringStepsEnum.REJECTION]: HiringStepsDescription.REJECTION,
  [HiringStepsEnum.CLOSE_VACANCY]: HiringStepsDescription.CLOSE_VACANCY,
  [HiringStepsEnum.ACCEPTED]: HiringStepsDescription.ACCEPTED,
  [HiringStepsEnum.RESUME_COMPLETION]: HiringStepsDescription.RESUME_COMPLETION,
}

export const listHiringSteps: Array<
  IItem<HiringStepsEnum, HiringStepsDescription>
> = [
  {
    item: HiringStepsDescription.INITIAL_STATUS,
    value: HiringStepsEnum.INITIAL_STATUS,
  },
  {
    item: HiringStepsDescription.OFFER,
    value: HiringStepsEnum.OFFER,
  },
  {
    item: HiringStepsDescription.REJECTION,
    value: HiringStepsEnum.REJECTION,
  },
  {
    item: HiringStepsDescription.CLOSE_VACANCY,
    value: HiringStepsEnum.CLOSE_VACANCY,
  },
  {
    item: HiringStepsDescription.ACCEPTED,
    value: HiringStepsEnum.ACCEPTED,
  },
  {
    item: HiringStepsDescription.RESUME_COMPLETION,
    value: HiringStepsEnum.RESUME_COMPLETION,
  },
]
