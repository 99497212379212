import React, { ElementRef, useEffect, useMemo, useRef, useState } from 'react'
import BaseSelect, { IItem } from 'components/ui/BaseSelect'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { useFormik } from 'formik'
import { getEmployees } from 'api/employees'
import { handlerError, setToastError } from 'utils/handlerError'
import { parseDataToBenefits, parseDataToEmployees } from 'utils/parseData'
import { ButtonTypes } from 'constants/buttonTypes'
import * as ST from './styled'
import { Placeholders } from 'constants/placeholders'
import { BenefitApplicationTypes } from 'types/model/benefitApplication'
import { editBenefitApplication } from 'api/benefitsApplications'
import { BenefitTypes } from 'types/model/benefit'
import { listBenefitOperationTypes } from 'constants/benefitOperationTypesDescription'
import CalendarInput from 'components/ui/inputs/Calendar'
import debounce from 'lodash/debounce'
import { Autocomplete, Box, TextField, useMediaQuery } from '@mui/material'
import { ROLES } from 'constants/roles'
import moment from 'moment/moment'
import { BreakPoints } from 'constants/breakPoints'
import * as Yup from 'yup'
import { RequiredFields } from 'constants/requiredFields'
import { UserTypes } from 'types/model/user'
import { ModalLoader } from 'components/ui/overlay/Modal/Loader'
import { UserStatuses } from 'constants/userStatuses'
import BaseInput from 'components/ui/inputs/BaseInput'
import { DATE_FORMAT_DTO } from 'constants/Date'
import BaseButton from 'components/ui/buttons/BaseButton'
import { Plus } from '../CreateGrade/styled'
import { ErrorMessages } from 'constants/errorMessages'
import { urlArrayNullable } from 'utils/validation/tests'
import { BenefitOperationTypes } from 'constants/benefitOperationTypes'

export interface Props {
  show: boolean
  onClose: SetState<boolean>
  onSuccess?: () => void
  benefitApplication: BenefitApplicationTypes.Model
  userData: UserTypes.Model[]
  benefitData: BenefitTypes.Model[]
  setDateValue?: (value: Date | null) => void
}

const CreateEmployeeEnum = {
  emptyString: '',
}

const selectsBenefitApplicationEnum = {
  targetUser: 'targetUser',
  commentary: 'commentary',
  value: 'value',
  benefit: 'benefit',
  benefitId: 'benefitId',
  cancellationDate: 'cancellationDate',
}

const operationType = 'operationType'

const enum CreateBenefitApplicationStrings {
  sizeInputBig = 'big',
  sizeInputSmall = 'small',
  surname = 'surname',
  name = 'name',
  email = 'email',
  emailPlaceholder = 'example@profsoft.pro',
  salary = 'salary',
  salaryHand = 'salaryHand',
  patronymic = 'patronymic',
  contactPhone = 'contactPhone',
  contactPhonePlaceholder = '+7 (000) 000–00–00',
  contactPhoneFormat = '+7 (###) ###-##-##',
  date = 'date',
  datePlaceholder = '01.01.2000',
  dateFormat = '##.##.####',
  department = 'department',
  departmentPlaceholder = 'Выберите отдел',
  post = 'post',
  postPlaceholder = 'Выберите должность',
  grade = 'grade',
  gradePlaceholder = 'Выберите грейд',
  none = 'none',
  file = 'file',
  hardwareType = 'hardwareType',
  cost = 'cost',
  responsibleUser = 'responsibleUser',
  serialNumber = 'serialNumber',
  type = 'type',
}

const EditBenefitApplication = ({
  show,
  onClose,
  benefitApplication,
  userData,
  benefitData,
  onSuccess,
  setDateValue,
}: Props) => {
  const modalRef = useRef<ElementRef<'div'>>(null)

  const [isAdmin, setAdmin] = useState<boolean>(false)
  const [isHR, setHR] = useState<boolean>(false)
  const [userList, setUserList] = useState<Array<IItem<number>>>([])
  const [benefit, setBenefit] = useState<BenefitApplicationTypes.Model>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [filteredBenefitList, setFilteredBenefitList] = useState<
    IItem<number>[]
  >(parseDataToBenefits(benefitData))
  const [cancellationDate, setCancellationDate] = useState<Date>(
    new Date(Date.now())
  )

  const {
    handleChange,
    values,
    resetForm,
    handleSubmit,
    isValid,
    touched,
    setFieldValue,
    validateForm,
    errors,
    setFieldTouched,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      targetUserId: benefit?.targetUser.id,
      targetUser: {
        value: benefit?.targetUser.id ?? 0,
        item:
          `${benefit?.targetUser.surname} ${benefit?.targetUser.name}` ?? '',
      },
      commentary: benefit?.commentary || '',
      value: benefit?.value || 1,
      benefitId: benefit?.benefit?.id,
      benefit: benefit?.benefit!,
      cancellationDate: benefit?.cancellationDate || '',
      type: benefit?.benefit.type,
      operationTypeData:
        listBenefitOperationTypes[benefit?.benefit.operationType! - 1],
      operationType:
        listBenefitOperationTypes[benefit?.benefit.operationType! - 1]?.value,
      links: benefit?.links ?? [],
    },
    onSubmit: () => {
      setIsLoading(true)
      const newObj = {
        targetUserId: values.targetUserId!,
        commentary: values.commentary,
        value: +values.value,
        benefitId: +values.benefit.id!,
        cancellationDate: moment(cancellationDate).format(DATE_FORMAT_DTO),
        links: values.links ?? [],
      }

      if (setDateValue) {
        setDateValue(cancellationDate)
      }
      editBenefitApplication(benefit?.id!, newObj)
        .then(() => {
          handleClose()
          resetForm()
          onSuccess?.()
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    validationSchema: Yup.object().shape({
      value: Yup.number().required(RequiredFields.base),
      benefitId: Yup.number().required(RequiredFields.base),
      type: Yup.string().required(RequiredFields.base),
      benefit: Yup.object().nullable().required(RequiredFields.base),
      operationType: Yup.number().required(RequiredFields.base),
      cancellationDate: Yup.string().required(RequiredFields.base),
      commentary: Yup.string().nullable(),
      links: Yup.array().nullable(),
    }),
  })

  const checkForm = (): void => {
    validateForm().then((errs) => {
      const entries = errs ? Object.entries(errs) : []

      if (!entries.length) {
        handleSubmit()
        return
      } else {
        handlerError(errs)
        Object.entries(errs).forEach(([key, value]) => {
          setToastError(`${key}: ${value}`)
        })
        return
      }
    })
  }

  const removeLink = (index: number) => {
    setFieldValue(
      'links',
      values.links.filter((link, linkIndex) => index !== linkIndex)
    )
    setFieldTouched(`links[${index}]`, false)
  }

  const addLink = () => {
    setFieldValue('links', [...values.links, ''])
    setFieldTouched(`links[${values.links.length}]`, false)
  }

  const changeLink = (link: string, index: number) => {
    setFieldValue(
      'links',
      values.links.map((linkItem, linkIndex) => {
        if (index === linkIndex) {
          return link
        }
        return linkItem
      })
    )
    setFieldTouched(`links[${index}]`, true)
  }

  const handleInputChange = (value: string) => {
    getEmployees(1, { status: [UserStatuses.active] }, value)
      .then((data) => {
        setUserList(parseDataToEmployees(data.data))
      })
      .catch((e) => {
        handlerError(e)
      })
  }

  const modalRoot = document.createElement('div')
  modalRoot.setAttribute('id', 'modal-root')

  const handleClose = (): void => {
    onClose(!show)
    resetForm()
  }

  const linksIsRequired = useMemo(
    () => values?.operationType === BenefitOperationTypes.withdrawal,
    [values?.operationType]
  )

  const isFullValid = useMemo(() => {
    return (
      isValid &&
      (linksIsRequired ? values.links.length > 0 : true) &&
      urlArrayNullable(values.links)
    )
  }, [isValid, values.links.length, JSON.stringify(values.links)])

  useEffect(() => {
    setUserList(parseDataToEmployees(userData))
  }, [userData])

  useEffect(() => {
    const roles: string[] = JSON.parse(
      localStorage.getItem('auth') as string
    )?.role
    setAdmin(roles?.includes(ROLES.admin))
    setHR(roles?.includes(ROLES.hr))
  }, [])

  useEffect(() => {
    setBenefit(benefitApplication)
    setCancellationDate(
      benefitApplication.cancellationDate
        ? new Date(benefitApplication.cancellationDate)
        : new Date(Date.now())
    )
  }, [benefitApplication])

  const isMobileDevice = useMediaQuery(
    `(max-width: ${BreakPoints.TABLETS_PORTRAIT})`
  )

  const SignupForm = () => {
    return (
      <>
        {!(isAdmin || isHR) && (
          <ST.TextWrapper>
            <ST.Label>
              <ST.LabelName>Сотрудник:</ST.LabelName>
              <ST.LabelValue>
                {benefit?.targetUser?.surname} {benefit?.targetUser?.name}{' '}
                {benefit?.targetUser?.patronymic}
              </ST.LabelValue>
            </ST.Label>
            <ST.Label>
              <ST.LabelName>Бенефит на счету:</ST.LabelName>
              <ST.HeaderLabel>
                {benefit?.targetUser?.benefitWallet.balance || 0}
              </ST.HeaderLabel>
            </ST.Label>
          </ST.TextWrapper>
        )}
        <ST.InputsBlock>
          {(isAdmin || isHR) && (
            <ST.SelectWrapper>
              <ST.Label>Сотрудник</ST.Label>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                clearOnBlur={false}
                blurOnSelect={true}
                noOptionsText={'Нет доступных вариантов'}
                options={userList}
                style={
                  isMobileDevice
                    ? {
                        display: 'flex',
                        alignItems: 'center',
                        color: '#000000',
                        backgroundColor: '#f8f8f8',
                        boxSizing: 'border-box',
                        borderRadius: '10px',
                        height: '48px',
                        fontWeight: '400',
                        fontSize: '16px',
                        width: '100%',
                      }
                    : {
                        color: '#000000',
                        backgroundColor: '#f8f8f8',
                        padding: '0',
                        marginBottom: '30px',
                        marginTop: '10px',
                        borderRadius: '10px',
                        height: '20px',
                        fontWeight: '400',
                        fontSize: '16px',
                        width: '100%',
                      }
                }
                sx={{
                  '& .MuiInputBase-root': {
                    padding: '0 !important',
                  },
                  '& .Mui-focused.MuiFormLabel-root.MuiInputLabel-root': {
                    opacity: '0',
                  },
                  '& .MuiFormLabel-filled': {
                    opacity: '0 !important',
                  },
                  '& .MuiFormLabel-filled + div > fieldset > legend': {
                    display: 'none !important',
                  },
                  '& .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                    {
                      borderColor: 'transparent',
                    },
                  '& .MuiFormLabel-root': {
                    top: '-8px',
                    color: '#606060',
                    opacity: '0.5',
                    fontFamily: 'Golos, sans-serif !important',
                  },
                  '& .MuiAutocomplete-input': {
                    ...(isMobileDevice
                      ? {
                          paddingLeft: '16px !important',
                          paddingRight: '16px !important',
                          fontFamily: 'Golos, sans-serif !important',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: '140%',
                          color: 'black',
                        }
                      : {
                          paddingLeft: '10px !important',
                          fontFamily: 'Golos, sans-serif !important',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: '140%',
                          color: 'black',
                        }),
                  },
                  '& fieldset': {
                    border: 'none !important',
                  },
                }}
                filterSelectedOptions
                value={values.targetUser}
                onInputChange={debounce(
                  (e) => handleInputChange(e?.target?.value),
                  1000
                )}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.value === value.value
                }
                filterOptions={(options: any) => options}
                getOptionLabel={(option: any) => option.item}
                onChange={(event, value: any) => {
                  if (value) {
                    setFieldValue('targetUserId', value?.value)
                    setFieldValue('targetUser', value)
                  } else {
                    setFieldValue('targetUserId', null)
                    setFieldValue('targetUser', null)
                  }
                }}
                renderOption={(props: any, option: any) => {
                  return (
                    <Box
                      component="li"
                      style={{ backgroundColor: '#f8f8f8', border: 'none' }}
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      {...props}
                      key={option.value}
                    >
                      {option.item}
                    </Box>
                  )
                }}
                renderInput={(params: any) => (
                  <TextField
                    style={{
                      backgroundColor: '#f8f8f8',
                      borderWidth: 0,
                      '::placeholder': {
                        opacity: '0.5',
                      },
                      borderRadius: '10px',
                    }}
                    {...params}
                    label="Выберите сотрудника"
                  />
                )}
              />
            </ST.SelectWrapper>
          )}
          <ST.SelectWrapper>
            <ST.Label>
              Тип<ST.Star>*</ST.Star>
            </ST.Label>
            <BaseSelect
              isSmallSelect={false}
              placeHolder={Placeholders.benefitOperationalType}
              listItems={listBenefitOperationTypes}
              name={operationType}
              value={values?.operationTypeData?.item || ''}
              typeSelect={operationType}
              onChange={(newValue) => {
                setFilteredBenefitList(
                  parseDataToBenefits(
                    benefitData.filter(
                      (benefitSingle) =>
                        benefitSingle.operationType === newValue?.value
                    )
                  )
                )
                setFieldValue(operationType, newValue?.value)
                setFieldValue('benefit', null)
              }}
            />
          </ST.SelectWrapper>
          <ST.InputWrapper>
            <ST.Label>
              Вид<ST.Star>*</ST.Star>
            </ST.Label>
            <BaseSelect
              isSmallSelect={false}
              placeHolder={Placeholders.benefitOperationalType}
              listItems={filteredBenefitList}
              name={selectsBenefitApplicationEnum.benefit}
              value={values.benefit?.type || ''}
              typeSelect={operationType}
              onChange={(newValue) => {
                setFieldValue(
                  'benefit',
                  newValue
                    ? {
                        id: newValue?.value,
                        type: newValue?.item,
                      }
                    : null
                )
              }}
              required
              dropdownStyle={{ maxHeight: 300 }}
            />
          </ST.InputWrapper>
          <ST.InputWrapper>
            <BaseInput
              label="Номинал"
              placeholder={CreateEmployeeEnum.emptyString}
              id={selectsBenefitApplicationEnum.value}
              name={selectsBenefitApplicationEnum.value}
              value={values.value}
              onChange={(e) => {
                setFieldValue('value', e.target.value)
                handleChange(e)
              }}
              type="number"
              customInput={ST.Input}
              required
            />
          </ST.InputWrapper>
          <ST.InputWrapper>
            <ST.Label>
              Дата списания<ST.Star>*</ST.Star>
            </ST.Label>
            {cancellationDate && (
              <CalendarInput
                setStartDate={setCancellationDate}
                startDate={cancellationDate}
                minDate={new Date(Date.now())}
              />
            )}
          </ST.InputWrapper>

          <ST.InputWrapper>
            <ST.Label>Ссылки{linksIsRequired && <ST.Star>*</ST.Star>}</ST.Label>
            {values.links.map((link, index) => {
              return (
                <ST.InputWrapperWithDelete key={`links-${index}`}>
                  <ST.Input
                    type="text"
                    placeholder={Placeholders.url}
                    name={`links-${index}`}
                    onBlur={handleBlur(`links[${index}]`)}
                    value={link}
                    onChange={(e) => {
                      changeLink(e.target.value, index)
                    }}
                  />
                  <ST.CloseWrapper>
                    <Close
                      onClick={() => {
                        removeLink(index)
                      }}
                    />
                  </ST.CloseWrapper>
                </ST.InputWrapperWithDelete>
              )
            })}
            {values.links.length > 0 &&
            Array.isArray(touched.links) &&
            touched.links.filter((link: boolean) => link).length > 0 &&
            !urlArrayNullable(values.links) ? (
              <ST.ErrorText style={{ marginBottom: '10px' }}>
                {ErrorMessages.incorrectURL}
              </ST.ErrorText>
            ) : (
              ''
            )}
            <ST.InputSmallWrapperButton>
              <ST.AddButton
                disabled={
                  values.links.filter((link) => (link ?? '').length === 0)
                    .length > 0
                }
                onClick={() => addLink()}
              >
                <Plus />
                Добавить ссылку
              </ST.AddButton>
            </ST.InputSmallWrapperButton>
          </ST.InputWrapper>

          <ST.InputWrapper>
            <ST.Label>Комментарий</ST.Label>
            <ST.InputTextArea
              placeholder={Placeholders.commentary}
              sizeInput={CreateBenefitApplicationStrings.sizeInputBig}
              id={selectsBenefitApplicationEnum.commentary}
              name={selectsBenefitApplicationEnum.commentary}
              value={values.commentary}
              onChange={handleChange}
            />
          </ST.InputWrapper>
        </ST.InputsBlock>

        <ST.ButtonWrapper>
          <ST.Button
            type={ButtonTypes.submit}
            disabled={!isFullValid}
            onClick={checkForm}
          >
            Подтвердить
          </ST.Button>
          <BaseButton
            typeButton={ButtonTypes.secondary}
            type={'submit'}
            text={'Отменить'}
            onClick={handleClose}
          />
        </ST.ButtonWrapper>
      </>
    )
  }

  return (
    <>
      {show ? (
        <ST.ModalOverlay>
          <ST.Modal ref={modalRef} onClick={(e) => e.stopPropagation()}>
            {isLoading && (
              <ModalLoader height={modalRef.current?.scrollHeight} />
            )}
            <ST.ModalContent>
              <ST.Close onClick={handleClose}>
                <Close />
              </ST.Close>
              <ST.ModalTitle>Редактирование заявки на бенефит</ST.ModalTitle>
              {SignupForm()}
            </ST.ModalContent>
          </ST.Modal>
        </ST.ModalOverlay>
      ) : null}
    </>
  )
}

export default EditBenefitApplication
