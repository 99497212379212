import React, { FC } from 'react'
import * as ST from 'components/newCandidate/styled'
import { FormikProps } from 'formik'
import { ReactComponent as Plus } from 'assets/icons/plusAddButton.svg'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { Placeholders } from 'constants/placeholders'
import { FormikFields } from 'constants/formikFields'
import { listHiringLanguagesTypes } from 'constants/hiringLanguagesTypes'
import { listHiringLanguagesLevelTypes } from 'constants/hiringLanguagesLevelTypes'
import BaseSelect, { IItem } from 'components/ui/BaseSelect'
import { NewCandidateTypes as Types } from 'components/newCandidate/types'
import { IGeneralInfoEditingFormik } from 'types/userProfileTypes'
import AutocompleteInput from 'components/ui/inputs/AutocompleteInput'

interface LanguagesProps {
  values: Types.Form | IGeneralInfoEditingFormik
  setFieldValue: FormikProps<Types.Form>['setFieldValue']
  selectedLanguage: IItem<number> | null
  updateForeignLanguageList: () => void
  handleLanguageChange: (newValue: IItem<number> | null) => void
  isForUser: boolean
}

const Languages: FC<LanguagesProps> = ({
  values,
  setFieldValue,
  selectedLanguage,
  updateForeignLanguageList,
  handleLanguageChange,
  isForUser = false,
}) => (
  <ST.Languages>
    <ST.LineContent>
      {!isForUser && (
        <ST.LeftContent>
          <ST.LeftTitle>Языки</ST.LeftTitle>
        </ST.LeftContent>
      )}
      <ST.RightContent>
        {isForUser && <ST.LeftTitle>Языки</ST.LeftTitle>}
        <ST.LanguageLine>
          <ST.InputSmallWrapper>
            <ST.LabelWrapper>
              <ST.Label>Родной язык</ST.Label>
            </ST.LabelWrapper>
            <ST.SelectWrapper>
              <AutocompleteInput
                options={listHiringLanguagesTypes}
                value={selectedLanguage || { item: '', value: 0 }}
                isOptionEqualToValue={(option, val) =>
                  option.value === val?.value
                }
                getOptionLabel={(option) => option.item}
                placeholder="Выберите язык"
                onChange={(val) => {
                  if (val) {
                    setFieldValue('nativeLanguage', val.item)
                    handleLanguageChange(val)
                  } else {
                    setFieldValue('nativeLanguage', null)
                    handleLanguageChange(null)
                  }
                }}
              />
            </ST.SelectWrapper>
          </ST.InputSmallWrapper>
          <ST.InputSmallWrapper></ST.InputSmallWrapper>
          <ST.InputSmallWrapper></ST.InputSmallWrapper>
        </ST.LanguageLine>

        {values.foreignLanguage
          ?.map((lang) => ({ item: lang.name, value: lang.level }))
          ?.map((value, index, array) => (
            <ST.CandidateInfo key={`foreignLanguage-${index}`}>
              <ST.ExpInfo>
                <AutocompleteInput
                  options={listHiringLanguagesTypes.filter(
                    (opt) =>
                      values.foreignLanguage.findIndex(
                        (f) => f.nameType?.value === opt.value
                      ) === -1
                  )}
                  label={`Язык ${index + 1}`}
                  value={value}
                  isOptionEqualToValue={(option, val) =>
                    option.value === val?.value
                  }
                  onChange={(val) => {
                    setFieldValue(
                      'foreignLanguage',
                      values.foreignLanguage.map((lang, i) =>
                        i === index
                          ? { ...lang, name: val?.item ?? '', nameType: val }
                          : lang
                      )
                    )
                  }}
                  placeholder="Выберите язык"
                  getOptionLabel={(option) => option.item}
                  disableClearable
                />
                <ST.InputWithDeleteWrapper>
                  <ST.SocialNetworkInputWrapper style={{ minWidth: 250 }}>
                    <BaseSelect
                      label="Уровень владения"
                      value={
                        listHiringLanguagesLevelTypes.find(
                          (level) => level.value === value.value
                        )?.item ?? ''
                      }
                      isSmallSelect={false}
                      placeHolder={Placeholders.typeFromList}
                      listItems={listHiringLanguagesLevelTypes}
                      name={`foreignLanguage[${index}].level`}
                      typeSelect={FormikFields.reviewType}
                      passValue={(_, b) => {
                        setFieldValue(
                          'foreignLanguage',
                          values.foreignLanguage?.map((item) =>
                            item.name === value.item
                              ? {
                                  ...item,
                                  level: b,
                                  levelType: {
                                    item:
                                      listHiringLanguagesLevelTypes.find(
                                        (e) => e.value === b
                                      )?.item ?? '',
                                    value: b,
                                  },
                                }
                              : item
                          )
                        )
                      }}
                      style={{ minWidth: 250 }}
                    />
                  </ST.SocialNetworkInputWrapper>
                  <ST.WrapperDelete isBlur={array.length > 1}>
                    <Close
                      onClick={() => {
                        if (array.length > 1) {
                          const newValue = values.foreignLanguage?.filter(
                            (item) => item.name !== value.item
                          )
                          setFieldValue('foreignLanguage', newValue)
                        }
                      }}
                    />
                  </ST.WrapperDelete>
                </ST.InputWithDeleteWrapper>
              </ST.ExpInfo>
            </ST.CandidateInfo>
          ))}
        <ST.WrapperButton>
          <ST.AddButton
            onClick={updateForeignLanguageList}
            disabled={values.foreignLanguage?.some(
              (lang) => !lang.name || !lang.level
            )}
          >
            <Plus />
            Добавить язык
          </ST.AddButton>
        </ST.WrapperButton>
      </ST.RightContent>
    </ST.LineContent>
  </ST.Languages>
)

export default Languages
